<script>
  import { onMount } from "svelte";
  import { request, uploadFile } from "../lib/tools/request.js";
  import AutoComplete from "simple-svelte-autocomplete";
  import WarehouseInput from "../lib/components/WarehouseInput.svelte";
  import Button from "../lib/components/Button.svelte";
  import page from "page";
  import StockItemHistory from "../lib/components/StockItemHistory.svelte";
  import Modal from "../lib/components/Modal.svelte";
  import { sizeLabels } from "../lib/stores/sizeLabels.js";
  import SizeLabel from "../lib/components/SizeLabel.svelte";
  import Editor from "../lib/components/Editor.svelte";
  import StockStorageTable from "../lib/components/StockStorageTable.svelte";
  import StockItemVendorCodesColumn from "../lib/components/StockItemVendorCodesColumn.svelte";

  export let params;
  let loading = true;
  let title = "Без названия";
  let categories = [];
  let item = {};
  let error = {};
  let codes = {};
  let sizeResultMap = {};
  let sizesDebounceTimer = {};
  let showHistory = false;
  let codesColumnsCount = 1;

  let itemId = null;
  $: itemId = params.id === "add" ? null : params.id;

  const fetchStockItem = async () => {
    if (!itemId) {
      item = {
        description: "",
        careRecommendation: "",
        media: [],
      };
      return;
    }

    loading = true;

    const { ok, data } = await request({
      path: `stock-items/${itemId}`,
    });

    if (ok) {
      const { data: category } = await request({
        path: `stock-categories/${data.category}`,
      });
      data.category = category;
      item = data;
      title = item.title;
    }

    setTimeout(() => {
      loading = false;
    }, 300);
  };

  const fetchCategories = async () => {
    const { ok, data } = await request({
      path: "stock-categories",
    });

    if (ok) {
      categories = [...data.results];
    }
  };

  const uploadSizeTable = async (event) => {
    const file = await uploadFile(event.target.files[0]);
    item = {
      ...item,
      sizeTable: file.url,
    };
    await saveFiles();
  };

  const uploadMedia = async (key, event) => {
    loading = true;

    const media = await Promise.all(
      Object.keys(event.target.files)
        .sort()
        .map((k) => uploadFile(event.target.files[k])),
    );

    item = {
      ...item,
      [key]: [...item[key], ...media],
    };

    await saveFiles();
    loading = false;
  };

  const saveFiles = async () => {
    if (!item.id) {
      return;
    }

    await request({
      path: `stock-items/${item.id}`,
      json: {
        id: item.id,
        media: item.media,
        textilePictures: item.textilePictures,
        sizeTable: item.sizeTable,
      },
    });
  };

  const save = async () => {
    loading = true;
    const path = item.id ? `stock-items/${item.id}` : "stock-items";
    const {
      ok,
      data,
      error: saveError,
    } = await request({
      path,
      json: {
        ...item,
        discount: item.discount || 0,
        category: item?.category?.id,
      },
    });
    loading = false;
    error = { ...saveError };

    if (ok && !item.id && data) {
      page(`/stock/${data.id}`);
    }

    if (ok) {
      title = data.title;
      item = { ...item, updated: true };
      setTimeout(() => {
        item = { ...item, updated: false };
      }, 1000);
    }
  };

  const deleteFile = async (key, media) => {
    item = {
      ...item,
      [key]: item[key].filter((m) => m.url !== media.url),
    };
    await saveFiles();
  };

  const moveFile = async (key, index, direction) => {
    if (index === 0 && direction < 0) {
      return;
    }

    if (index === item[key].length - 1 && direction > 0) {
      return;
    }
    const temp = item[key][index];
    item[key][index] = item[key][index + direction];
    item[key][index + direction] = temp;
    item = {
      ...item,
    };
    await saveFiles();
  };

  const deleteItem = async () => {
    if (confirm("Вы уверены?")) {
      const { ok } = await request({
        path: `stock-items/${item.id}`,
        method: "DELETE",
      });
      if (ok) {
        page("/stock");
      }
    }
  };

  const fetchCodes = async () => {
    if (!itemId) {
      return;
    }

    const { ok, data } = await request({
      path: "stock-items-codes",
      params: {
        stock_item: itemId,
      },
    });

    if (ok) {
      codes = Object.fromEntries(sizeLabels.map((s) => [s, []]));
      data.results.forEach((c) => {
        codes[c.size][c.group] = {
          id: c.id,
          code: c.code,
          group: c.group,
        };
        codesColumnsCount = Math.max(codesColumnsCount, c.group);
      });
    }
  };

  const updateSizeCode = async (size, index, value) => {
    if (!itemId) {
      return;
    }

    const key = `${size}-${index}`;

    if (sizesDebounceTimer[key]) {
      clearTimeout(sizesDebounceTimer[key]);
    }

    sizesDebounceTimer[key] = setTimeout(async () => {
      const { ok } = await request({
        path: "stock-items-codes/update-code",
        json: {
          size,
          id: codes[size]?.[index]?.id ?? "",
          code: value,
          stockItem: itemId,
          group: index,
        },
      });

      sizeResultMap = {
        ...sizeResultMap,
        [key]: ok,
      };

      if (ok) {
        setTimeout(() => {
          sizeResultMap = {
            ...sizeResultMap,
            [key]: undefined,
          };
        }, 1000);
      }
    }, 1000);
  };

  onMount(async () => {
    await fetchStockItem();
    await Promise.all([fetchCategories(), fetchCodes()]);
    loading = false;
  });
</script>

<div class="w-full flex h-full justify-between gap-5">
  <Modal bind:active={showHistory}>
    <StockItemHistory itemId={item.id} />
  </Modal>
  <div class="h-full w-full bg-white py-5">
    <div class="flex flex-col gap-2 w-full text-sm">
      <div
        class="w-full flex justify-between items-center py-2 px-6 overflow-x-scroll gap-5"
      >
        <div class="flex gap-1 items-baseline">
          <span class="text-xl font-bold whitespace-nowrap">{title}</span>
          {#if item.vendorCode && item.published}
            <a
              target="_blank"
              class="text-black/50 text-sm"
              href="https://igandesigner.ru/{item.vendorCode}/"
              >Посмотреть на сайте</a
            >
          {/if}
        </div>
        {#if item.id}
          <div class="hidden">
            <button
              on:click={() => (showHistory = !showHistory)}
              class="h-full bg-slate-100 text-xl rounded-xl py-1 px-6 hover:bg-slate-200"
            >
              <i class="material-symbols-outlined">update</i>
            </button>
          </div>
        {/if}
      </div>
      <div class="flex flex-col overflow-hidden gap-2 w-full md:w-2/3 px-6">
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Медиа</span>
          </div>
          <div class="flex gap-2 w-full overflow-x-scroll whitespace-nowrap">
            {#if !item.media?.length}
              <div
                class="group relative w-44 h-44 bg-slate-200/25 shrink-0 flex justify-center items-center"
              >
                <span class="text-black/50">Нет медиа</span>
              </div>
            {/if}
            {#each item.media ?? [] as media, index}
              <div
                class="group relative w-44 h-44 b-slate-200/25 shrink-0 text-lg text-white flex justify-center items-center"
              >
                <button
                  on:click={async () => await deleteFile("media", media)}
                  class="hidden group-hover:flex absolute top-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <i class="material-symbols-outlined">delete</i>
                </button>
                <button
                  on:click={async () => await moveFile("media", index, -1)}
                  class="hidden group-hover:flex absolute bottom-0 left-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_back_ios_new</span
                  >
                </button>
                <button
                  on:click={async () => await moveFile("media", index, 1)}
                  class="hidden group-hover:flex absolute bottom-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_forward_ios</span
                  >
                </button>
                {#if media.contentType?.startsWith("image")}
                  <img
                    src={media.url}
                    class="w-full h-full object-cover object-center"
                    alt=""
                  />
                {:else}
                  <div
                    class="text-black w-full h-full flex flex-col gap-1 justify-center items-center border border-gray-200"
                  >
                    <span class="material-symbols-outlined">description</span>
                    <span>{media.name}</span>
                  </div>
                {/if}
              </div>
            {/each}
          </div>
        </div>
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          />
          <div>
            <label
              class="flex h-full justify-center items-center cursor-pointer"
              for="file">Добавить файл</label
            >
            <input
              accept="*"
              multiple
              on:change={(e) => uploadMedia("media", e)}
              type="file"
              id="file"
              class="opacity-0 w-[0.1px] h-[0.1px] absolute"
            />
          </div>
        </div>

        <div class="flex flex-col gap-1 md:flex-row md:gap-5 pt-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Ткани</span>
          </div>
          <div class="flex gap-2 w-full overflow-x-scroll whitespace-nowrap">
            {#if !item.textilePictures?.length}
              <div
                class="group relative w-44 h-44 bg-slate-200/25 shrink-0 flex justify-center items-center"
              >
                <span class="text-black/50">Нет тканей</span>
              </div>
            {/if}
            {#each item.textilePictures ?? [] as media, index}
              <div
                class="group relative w-44 h-44 b-slate-200/25 shrink-0 text-lg text-white flex justify-center items-center"
              >
                <button
                  on:click={async () =>
                    await deleteFile("textilePictures", media)}
                  class="hidden group-hover:flex absolute top-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <i class="material-symbols-outlined">delete</i>
                </button>
                <button
                  on:click={async () =>
                    await moveFile("textilePictures", index, -1)}
                  class="hidden group-hover:flex absolute bottom-0 left-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_back_ios_new</span
                  >
                </button>
                <button
                  on:click={async () =>
                    await moveFile("textilePictures", index, 1)}
                  class="hidden group-hover:flex absolute bottom-0 right-0 p-2 bg-black/80 rounded-lg justify-center items-center w-10 h-10"
                >
                  <span class="material-symbols-outlined"
                    >arrow_forward_ios</span
                  >
                </button>
                {#if media.contentType?.startsWith("image")}
                  <img
                    src={media.url}
                    class="w-full h-full object-cover object-center"
                    alt=""
                  />
                {:else}
                  <div
                    class="text-black w-full h-full flex flex-col gap-1 justify-center items-center border border-gray-200"
                  >
                    <span class="material-symbols-outlined">description</span>
                    <span>{media.name}</span>
                  </div>
                {/if}
              </div>
            {/each}
          </div>
        </div>
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          />
          <div>
            <label
              class="flex h-full justify-center items-center cursor-pointer"
              for="textileFile">Добавить ткань</label
            >
            <input
              accept="*"
              multiple
              on:change={(e) => uploadMedia("textilePictures", e)}
              type="file"
              id="textileFile"
              class="opacity-0 w-[0.1px] h-[0.1px] absolute"
            />
          </div>
        </div>

        <div class="flex flex-col gap-1 md:flex-row md:gap-5 pb-5 pt-10">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          />
          <label>
            <input type="checkbox" bind:checked={item.published} />
            <span>Показать на сайте</span>
          </label>
        </div>
        <WarehouseInput
          label="Бейдж"
          name="badge"
          required
          bind:value={item.badge}
          bind:error
        />
        <WarehouseInput
          label="Категория"
          name="category"
          required
          bind:value={item.category}
          bind:error
        >
          <AutoComplete
            items={categories}
            labelFieldName="title"
            valueFieldName="id"
            slot="input"
            hideArrow
            required
            selectName="categoryId"
            inputClassName="border !py-3 border-slate-200"
            bind:selectedItem={item.category}
          />
        </WarehouseInput>
        <WarehouseInput
          label="Группа"
          name="group"
          required
          bind:value={item.group}
          bind:error
        />
        <WarehouseInput
          label="Название"
          name="title"
          required
          bind:value={item.title}
          bind:error
        />
        <WarehouseInput
          label="Артикул"
          name="vendorCode"
          required
          bind:value={item.vendorCode}
          bind:error
        />
        <WarehouseInput
          label="Цена"
          name="price"
          required
          bind:value={item.price}
          bind:error
        />
        <WarehouseInput
          label="Скидка"
          name="discount"
          required
          bind:value={item.discount}
          bind:error
        />

        <div class="flex flex-col gap-1 md:flex-row md:gap-5 pb-3">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Цена со скидкой</span>
          </div>
          <div class="w-full text-slate-400">
            {item.discountPrice ?? 0}
          </div>
        </div>
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Описание</span>
          </div>
          <div class="w-full">
            <Editor bind:content={item.description} />
          </div>
        </div>
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Рекомендация по уходу</span>
          </div>
          <div class="w-full">
            <Editor bind:content={item.careRecommendation} />
          </div>
        </div>
        <div class="flex flex-col gap-1 md:flex-row md:gap-5">
          <div
            class="flex w-36 flex-shrink-0 flex-grow-0 items-center text-slate-400"
          >
            <span>Таблица размеров</span>
          </div>
          <div class="h-40 w-full flex gap-3">
            {#if item.sizeTable}
              <img
                src={item.sizeTable}
                alt=""
                class="h-full object-cover object-center"
              />
            {/if}
            <label
              class="flex h-full justify-center items-center cursor-pointer"
            >
              <input
                accept="image/*"
                multiple
                on:change={uploadSizeTable}
                type="file"
                id="file"
                class="opacity-0 w-[0.1px] h-[0.1px] absolute"
              />
              <span
                class="group relative w-20 h-20 bg-slate-200/25 shrink-0 flex justify-center items-center"
              >
                <span class="text-black/50"
                  >{item.sizeTable ? "Заменить" : "Загрузить"}</span
                >
              </span></label
            >
          </div>
        </div>
        <div class="h-9">
          <Button
            disabled={loading}
            accent={!item.updated}
            success={item.updated}
            on:click={save}>Сохранить</Button
          >
        </div>
        {#if itemId}
          <div class="h-9">
            <Button disabled={loading} danger on:click={deleteItem}
              >Удалить</Button
            >
          </div>
        {/if}
      </div>
      <div class="pt-10">
        {#if item.id}
          <StockStorageTable bind:item on:updated={fetchStockItem} />
        {/if}
      </div>
      <div class="pt-4">
        <div class="px-5 flex flex-col gap-2">
          <div class="pb-4 flex gap-5 items-baseline">
            <span class="text-xl font-bold">Штрихкоды</span>
            <button
              on:click={() => (codesColumnsCount += 1)}
              class="text-gray-400">Добавить колонку +</button
            >
          </div>
          <div class="flex gap-5">
            <div class="shrink-0 flex flex-col gap-2">
              {#each sizeLabels as size}
                <div
                  class="w-[50px] h-[46px] uppercase text-slate-400 font-bold flex items-center"
                >
                  <SizeLabel {size} />
                </div>
              {/each}
            </div>

            {#if item.id}
              <StockItemVendorCodesColumn stockItemId={item.id} />
            {/if}

            <div
              class="w-full whitespace-nowrap overflow-x-scroll flex flex-col gap-2"
            >
              {#each sizeLabels as size}
                <div class="flex gap-2">
                  {#each Array(codesColumnsCount + 1).fill(null) as _, index}
                    <div>
                      <input
                        type="text"
                        class:border-red-500={sizeResultMap[
                          `${size}-${index}`
                        ] === false}
                        class:border-green-500={sizeResultMap[
                          `${size}-${index}`
                        ] === true}
                        class:border-slate-200={sizeResultMap[
                          `${size}-${index}`
                        ] === undefined}
                        class="transition-colors border h-[46px] md:w-[200px] max-w-full px-1"
                        on:input={(e) =>
                          updateSizeCode(size, index, e.currentTarget.value)}
                        value={codes[size]?.[index]?.code ?? ""}
                      />
                    </div>
                  {/each}
                </div>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
